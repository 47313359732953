body {
	font-size: 1.3em;
	background-color: #181a1c;
	color: white;
	font-family: 'Open Sans', sans-serif;
}

#gutters {
	/* container */
	display: grid;
	grid-template-columns: auto [main-start] 900px [main-end] auto;
}

#navbar {
	/* item */
	grid-column: main-start / main-end;

	/* container */
	display: grid;
	grid-template-columns: [logo-start] auto [logo-end] auto [links-start] auto [links-end];
}

#logo {
	/* item */
	grid-column: logo-start / logo-end;
}

#links {
	/* item */
	grid-column: links-start / links-end;
	justify-self: end;
	align-self: end;
}

.link {
	padding-left: 10px;
	padding-right: 10px;
	cursor: pointer;
	color: #cccccc;
}

.link:hover {
	color: #ffffff;
}

#main {
	/* item */
	grid-column: main-start / main-end;
}

#footer {
	/* item */
	grid-column: main-start / main-end;
}

.header {
	font-size: 2em;
	font-family: 'Playfair Display', sans-serif;
	font-weight: 100;
}