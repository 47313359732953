body {
  color: #fff;
  background-color: #181a1c;
  font-family: Open Sans, sans-serif;
  font-size: 1.3em;
}

#gutters {
  grid-template-columns: auto[main-start] 900px[main-end] auto;
  display: grid;
}

#navbar {
  grid-column: main-start / main-end;
  grid-template-columns: [logo-start] auto[logo-end] auto[links-start] auto[links-end];
  display: grid;
}

#logo {
  grid-column: logo-start / logo-end;
}

#links {
  grid-column: links-start / links-end;
  place-self: end;
}

.link {
  cursor: pointer;
  color: #ccc;
  padding-left: 10px;
  padding-right: 10px;
}

.link:hover {
  color: #fff;
}

#main, #footer {
  grid-column: main-start / main-end;
}

.header {
  font-family: Playfair Display, sans-serif;
  font-size: 2em;
  font-weight: 100;
}



/*# sourceMappingURL=index.21ef13fb.css.map */
